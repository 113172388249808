<template>
  <section class="sub_banner" :style="{backgroundImage: `url(${bannerBg})`}">
    <hgroup>
      <h3>{{title}}</h3>
      <h5>{{subTitle}}</h5>
    </hgroup>
  </section>
</template>

<script>
export default {
  name: 'subBanner',
  props: {
    title: String,
    subTitle: String,
    bannerBg: String
  }
}
</script>

<style lang="less" scoped>
.sub_banner{
  .flex(flex-start);
  .full_bg;
  width: 100%;
  height: 390px;
  color: #fff;
  text-align: center;
  line-height: 1;
  margin-top: -@header-height;
  background-color: #f1f1f1;
  hgroup{
    max-width: 650px;
    margin: @header-height auto 0;
  }
  h3{
    font-size:46px;
    margin-bottom: 20px;
  }
  h5{
    font-size:30px;
    text-transform: uppercase;
  }
}
</style>
